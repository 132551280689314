<template>
  <b-table
    v-if="dataList.length > 0"
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
    fixed
  >
    <template #cell(title)="data">
      {{ data.item.title }}
      <div v-if="data.item.onumber">
        <small class="text-primary">{{ data.item.onumber }}</small>
      </div>
    </template>
    <template #cell(offers_status)="data">
      {{ data.item.offers_status }}
      <div class="font-small-2 text-primary">
        {{ data.item.username }}
      </div>
    </template>
    <template #cell(username)="data">
      {{ data.item.username }}
      <div v-if="data.item.created">
        <small class="text-primary"> {{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
      </div>
    </template>
    <template #cell(control)="data">
      <list-buttons
        primary-text="Görüntüle"
        :primary-action="'/app/offers/view/' + data.item.id"
        edit-text="Revize Oluştur"
        :edit-action="'/app/offers/revised/' + data.item.id"
        :delete-action="removeData"
        :data-id="data.item.id"
      />
    </template>
  </b-table>
  <b-alert
    v-else
    show
    variant="info"
    class="ml-2 mr-2 mt-2 text-center"
  >
    <div class="alert-body">
      <FeatherIcon icon="InfoIcon" /> Kayıtlı teklif bulunmuyor.
    </div>
  </b-alert>
</template>
<script>
import { BAlert, BTable } from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'OfferList',
  components: {
    BAlert,
    BTable,
    ListButtons,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    removeData: {
      type: Function,
      required: true,
    },
  },
}
</script>
