<template>
  <div>
    <b-card>
      <b-row class="d-flex align-items-center justify-content-center">
        <b-col>
          <search
            :reset-search="resetSearch"
            :search-data="searchData"
          />
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            :to="$route.path + '/customer-search'"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <offer-list
        :remove-data="removeData"
        :data-list="dataList"
        :fields="fields"
      />
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BPagination, BButton, BCardFooter,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Search from '@/views/Admin/Offers/elements/Search.vue'
import OfferList from '@/views/Admin/Offers/Index/OfferList.vue'

const tableName = 'offers'
export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BPagination,
    BButton,
    BCardFooter,
    OfferList,
    Search,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'title',
          label: 'TEKLİF',
        },
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'username',
          label: 'Oluşturan',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'customers.company AS company',
          'offers.odate AS odate',
          'offers.title AS title',
          'offers.created AS created',
          'CONCAT(customers.cnumber,"-O",offers.onumber) AS onumber',
          'offer_statuses.title AS offers_status',
          'users.name AS username',
        ],
        order_by: ['offers.id', 'DESC'],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['offers/dataList']
    },
    dataCounts() {
      return this.$store.getters['offers/dataCounts']
    },
    saveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
    search() {
      return this.$store.getters['offers/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.search.keyword,
          'customers.phone': this.search.keyword,
          'customers.cnumber': this.search.keyword,
          'offers.title': this.search.keyword,
          'offers.onumber': this.search.keyword,
          'users.name': this.search.keyword,
        }
      }
      if (this.search.id_users) {
        this.dataQuery.where['offers.id_users'] = this.search.id_users
      } else {
        this.dataQuery.where = {}
      }
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search.keyword = null
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('offers/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('offers/removeData', id)
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız',
                  text: response.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
